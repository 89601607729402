/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.desk {
  padding: 0 30px;
}
#head {
  margin: 62px 0;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 4%;
  margin-left: 4%;
}
.area .part {
  margin-right: 4%;
  margin-left: 4%;
  width: 92%;
}
.area > .slim .part {
  width: 92%;
}
.cb-layout2 .main,
.cb-layout5 .main {
  width: 100%;
}
.cb-layout2 .main > .unit,
.cb-layout5 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main .foot,
.cb-layout5 .main .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.cb-layout2 .main .part,
.cb-layout5 .main .part {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.cb-layout2 .main .tiny,
.cb-layout5 .main .tiny {
  width: 46%;
}
.cb-layout2 .main > .slim,
.cb-layout5 .main > .slim {
  width: 50%;
}
.cb-layout2 .main > .slim .foot,
.cb-layout5 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout5 .main > .slim .part {
  margin-right: 4%;
  margin-left: 4%;
}
.cb-layout2 .main > .slim .part,
.cb-layout5 .main > .slim .part {
  width: 92%;
}
.cb-layout2 .main > .slim .tiny,
.cb-layout5 .main > .slim .tiny {
  width: 92%;
}
.cb-layout3 .main,
.cb-layout4 .main,
.cb-layout6 .main {
  width: 100%;
}
.cb-layout3 .main > .unit,
.cb-layout4 .main > .unit,
.cb-layout6 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .main .foot,
.cb-layout4 .main .foot,
.cb-layout6 .main .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.cb-layout3 .main .part,
.cb-layout4 .main .part,
.cb-layout6 .main .part {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.cb-layout3 .main .tiny,
.cb-layout4 .main .tiny,
.cb-layout6 .main .tiny {
  width: 46%;
}
.cb-layout3 .main > .slim,
.cb-layout4 .main > .slim,
.cb-layout6 .main > .slim {
  width: 50%;
}
.cb-layout3 .main > .slim .foot,
.cb-layout4 .main > .slim .foot,
.cb-layout6 .main > .slim .foot,
.cb-layout3 .main > .slim .part,
.cb-layout4 .main > .slim .part,
.cb-layout6 .main > .slim .part {
  margin-right: 4%;
  margin-left: 4%;
}
.cb-layout3 .main > .slim .part,
.cb-layout4 .main > .slim .part,
.cb-layout6 .main > .slim .part {
  width: 92%;
}
.cb-layout3 .main > .slim .tiny,
.cb-layout4 .main > .slim .tiny,
.cb-layout6 .main > .slim .tiny {
  width: 92%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side .foot {
  margin-right: 4%;
  margin-left: 4%;
}
.side .part {
  margin-right: 4%;
  margin-left: 4%;
  width: 92%;
}
.side > .slim .part {
  width: 92%;
}
div.main {
  width: 52%;
  margin-left: -2%;
}
.cb-layout2 div.main,
.cb-layout3 div.main,
.cb-layout4 div.main,
.cb-layout5 div.main,
.cb-layout6 div.main {
  width: 104%;
  margin-left: -2%;
}
div.side {
  float: right;
  width: 52%;
  margin-right: -2%;
}
.cb-layout1 div.main div.seam,
.cb-layout1 div.side div.seam {
  margin-left: 4%;
  margin-right: 4%;
  width: 92%;
}
.cb-layout1 div.main div.seam div.part,
.cb-layout1 div.side div.seam div.part {
  margin-left: 6%;
  margin-right: 6%;
  width: 88%;
}
.cb-layout3 div.main div.seam {
  margin-left: 2%;
  margin-right: 2%;
}
.cb-layout3 div.main div.seam.wide {
  width: 96%;
}
.cb-layout3 div.main div.seam.wide div.part {
  margin-left: 3%;
  margin-right: 3%;
  width: 94%;
}
.cb-layout3 div.main div.seam.wide div.part.tiny {
  width: 44%;
}
.cb-layout3 div.main div.seam.slim {
  width: 46%;
}
.cb-layout3 div.main div.seam.slim div.part {
  margin-left: 6%;
  margin-right: 6%;
  width: 88%;
}
.cb-layout5 div.main div.seam {
  margin-left: 2%;
  margin-right: 2%;
}
.cb-layout5 div.main div.seam.wide {
  width: 96%;
}
.cb-layout5 div.main div.seam.wide div.part {
  margin-left: 3%;
  margin-right: 3%;
  width: 94%;
}
.cb-layout5 div.main div.seam.wide div.part.tiny {
  width: 44%;
}
.cb-layout5 div.main div.seam.slim {
  width: 46%;
}
.cb-layout5 div.main div.seam.slim div.part {
  margin-left: 6%;
  margin-right: 6%;
  width: 88%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */